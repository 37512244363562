import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const InfoBlock = makeShortcode("InfoBlock");
const Box = makeShortcode("Box");
const Aligner = makeShortcode("Aligner");
const Button = makeShortcode("Button");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "character-types",
      "style": {
        "position": "relative"
      }
    }}>{`Character Types`}<a parentName="h1" {...{
        "href": "#character-types",
        "aria-label": "character types permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`We've looked into the nature of Git, Node and Hardhat and have been courageous enough to dive into the infamous `}<inlineCode parentName="p">{`node_modules`}</inlineCode>{` directory to understand more about the Open Zeppelin libraries and all they enable us to do in a safe and peer-reviewed manner. We've even written our first smart contract. Now it's time to speak of compilers, networks, types, tests, and tasks. `}</p>
    <p>{`Good engineering is about the conscious, clear and precise `}<a parentName="p" {...{
        "href": "/learn/module-0/play-of-pattern"
      }}>{`management`}</a>{` of `}<a parentName="p" {...{
        "href": "/learn/module-4/consensus/#brief"
      }}>{`trade-offs`}</a>{`. More than any specific skill, we wish to illustrate in this session how we think about different trade-offs. This session is not going to be about taking the easy route for “educational” purposes. We’re going to look deeply into the alternatives we pick and why, so that we can come to the end of this guild with a production-ready repo that you can actually understand and use yourself for your own creative work.`}</p>
    <h2 {...{
      "id": "preparation",
      "style": {
        "position": "relative"
      }
    }}>{`Preparation`}<a parentName="h2" {...{
        "href": "#preparation",
        "aria-label": "preparation permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <ol>
      <li parentName="ol">{`Breathe deeply for 20 minutes. This is a session meant to test your character.`}</li>
    </ol>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <p><strong parentName="p">{`Type the Truth`}</strong></p>
        <p><img parentName="p" {...{
            "src": "/images/build/truetype.png",
            "alt": "True Type"
          }}></img></p>
        <p>{`Each session in this build guild can be found on its own branch in the repo below:`}</p>
        <Aligner center mdxType="Aligner">
          <Button to="https://github.com/kernel-community/nfteasy/tree/lesson-1" mdxType="Button">Continue</Button>
        </Aligner>
      </Box>
    </InfoBlock>
    <h2 {...{
      "id": "recording",
      "style": {
        "position": "relative"
      }
    }}>{`Recording`}<a parentName="h2" {...{
        "href": "#recording",
        "aria-label": "recording permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Video src="https://www.youtube-nocookie.com/embed/7zHxndNEBaw" mdxType="Video" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      